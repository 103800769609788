import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import LoginMain from "../components/login"
import {graphql} from 'gatsby';
import { useTranslation } from "react-i18next";
const LoginPage = () => {

  const { t } = useTranslation();

  return(
    <Layout>
      <Seo 
        title={t('siteTitle')}
        description={t('siteDescription')}
      />
      <LoginMain />
    </Layout>
  )
}

export default LoginPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;