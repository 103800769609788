import React , {useEffect} from "react"
import PropTypes from "prop-types"
import "./layout.scss"
import { Provider } from "react-redux"
import { store } from "../redux/store"
import { useDispatch } from 'react-redux';
import { setLoginAction } from "../redux/auth/authSlice"

const Layout = ({ children }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    const savedToken = localStorage.getItem('authToken');
    if (savedToken) {
        dispatch(setLoginAction(JSON.parse(savedToken)));
    }
  }, [dispatch]);

  return (
    <Provider store={store}>
        {children}
    </Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
