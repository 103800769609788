import React from 'react';
import LoginSection from './loginSection';

const index = ({source}) => {
    return (
        <><main>
                <LoginSection source={source} />
            </main>
        </>
    );
};

export default index;